/**
 * This is gross, but I have to do it because service auth doesn't adhere to the OAuth2 spec.
 *      It sends scopes as a list, instead of a space-separated string.
 *      This is a polyfill for Array.prototype.split that will allow the OIDC library to work with service auth.
 *      If service auth ever gets fixed, this whole file and all it's uses should be removed.
 */

export function addShamefulSplitPolyfill() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Array.prototype.split = function (separator: string) {
        return this.join(" ").split(separator);
    };
}
