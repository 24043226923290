import { Box, Button, FormField, Header, Input, Modal, SpaceBetween, Toggle } from "@cloudscape-design/components";
import { FunctionComponent, useState } from "react";
import { Override } from "../../../types/Override";
import { Treatment } from "../../../types/Treatment";

export type AddOverrideModalProps = {
    existingOverrides?: Override[];
    onClose: () => void;
    onSave: (override: Pick<Override, "discriminator" | "treatment">) => Promise<void>;
};

const AddOverrideModal: FunctionComponent<AddOverrideModalProps> = props => {
    const { existingOverrides, onClose, onSave } = props;

    const [discriminator, setDiscriminator] = useState("");
    const [discriminatorError, setDiscriminatorError] = useState("");
    const [treatment, setTreatment] = useState(Treatment.T1);
    const [isSaving, setIsSaving] = useState(false);

    const handleDiscriminatorChange = (newDiscriminator: string) => {
        setDiscriminator(newDiscriminator);
        setDiscriminatorError("");
    };

    const handleSave = async () => {
        if (!discriminator) {
            setDiscriminatorError("Discriminator is required");
            return;
        }

        if (existingOverrides?.some(o => o.discriminator === discriminator)) {
            setDiscriminatorError("Another override with this discriminator already exists");
            return;
        }

        setIsSaving(true);
        await onSave({ discriminator, treatment });
        setIsSaving(false);
    };

    return (
        <Modal
            visible={true}
            header={<Header>Add Override</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            loading={isSaving}
                            disabled={!!discriminatorError}
                            variant="primary"
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => onClose()}
            size="medium"
        >
            <SpaceBetween size="m">
                <FormField
                    label="Discriminator"
                    description="The key passed by the client to check the feature flag"
                    constraintText="Required"
                    errorText={discriminatorError}
                >
                    <Input value={discriminator} onChange={e => handleDiscriminatorChange(e.detail.value)} />
                </FormField>

                <FormField label="Treatment" description="The overriden treatment for the discriminator">
                    <Toggle
                        checked={treatment === Treatment.T1}
                        onChange={e => setTreatment(e.detail.checked ? Treatment.T1 : Treatment.C)}
                    >
                        {treatment === Treatment.T1 ? "On" : "Off"}
                    </Toggle>
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

export default AddOverrideModal;
