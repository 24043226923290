import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { effApi } from "../api/effApi";

const store = configureStore({
    reducer: {
        [effApi.reducerPath]: effApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(effApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
