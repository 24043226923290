import { applyTheme } from "@cloudscape-design/components/theming";
import { applyDensity, Density } from "@cloudscape-design/global-styles";

/* eslint-disable @typescript-eslint/no-unused-vars */
const ASU_MAROON = "#8C1D40";
const ASU_GOLD = "#FFC627";
const BLACK = "#000000";
const WHITE = "#FFFFFF";
const GREY1 = "#FAFAFA";
const GREY2 = "#E8E8E8";
const GREY3 = "#D0D0D0";
const GREY4 = "#BFBFBF";
const GREY5 = "#747474";
const GREY6 = "#484848";
const GREY7 = "#191919";
/* eslint-enable @typescript-eslint/no-unused-vars */

applyDensity(Density.Compact);
applyTheme({
    theme: {
        tokens: {
            colorTextBodyDefault: BLACK,

            // headings
            colorTextHeadingDefault: BLACK,
            colorTextCounter: GREY5,

            // primary buttons
            colorBackgroundButtonPrimaryDefault: ASU_MAROON,
            colorBackgroundButtonPrimaryHover: BLACK,
            colorBackgroundButtonPrimaryActive: BLACK,

            // normal buttons
            colorBorderButtonNormalDefault: ASU_MAROON,
            colorTextButtonNormalDefault: ASU_MAROON,
            colorBackgroundButtonNormalDefault: WHITE,
            colorBorderButtonNormalHover: BLACK,
            colorTextButtonNormalHover: BLACK,
            colorBackgroundButtonNormalHover: WHITE,
            colorBorderButtonNormalActive: BLACK,
            colorTextButtonNormalActive: BLACK,
            colorBackgroundButtonNormalActive: WHITE,

            // links
            colorTextLinkDefault: ASU_MAROON,
            colorTextLinkHover: BLACK,
        },
        contexts: {
            header: {
                tokens: {
                    colorBackgroundLayoutMain: GREY7,

                    // title
                    colorTextHeadingDefault: ASU_GOLD,

                    // breadcrumbs
                    colorTextBreadcrumbCurrent: GREY4,
                    colorTextBreadcrumbIcon: GREY5,
                    colorTextLinkDefault: GREY2,
                    colorTextLinkHover: ASU_GOLD,
                },
            },
            "top-navigation": {
                tokens: {
                    // title
                    colorBackgroundContainerContent: WHITE,
                    colorTextTopNavigationTitle: BLACK,

                    // search bar
                    colorBackgroundInputDefault: WHITE,
                    colorBorderInputDefault: GREY4,
                    colorBorderInputFocused: GREY7,
                    colorTextBodyDefault: BLACK,
                    colorTextInputPlaceholder: GREY4,
                    colorTextLinkDefault: GREY4,
                    colorTextLinkHover: GREY7,

                    // utilities
                    colorTextInteractiveDefault: GREY6,
                    colorTextInteractiveHover: BLACK,
                    colorTextInteractiveActive: BLACK,
                    colorTextAccent: BLACK,
                    colorBackgroundDropdownItemDefault: WHITE,
                },
            },
        },
    },
});
