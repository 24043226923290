import { Link as CloudscapeLink } from "@cloudscape-design/components";
import { FunctionComponent, PropsWithChildren } from "react";
import { useHref, useNavigate } from "react-router-dom";

export type LinkProps = PropsWithChildren<{
    to: string;
    replace?: boolean;
    state?: string;
    target?: string;
}>;

const Link: FunctionComponent<LinkProps> = ({ to, replace = false, state, target, children }) => {
    const href = useHref(to);
    const navigate = useNavigate();

    return (
        <CloudscapeLink
            href={href}
            onFollow={e => {
                e.preventDefault();
                navigate(to, { replace, state });
            }}
            target={target}
        >
            {children}
        </CloudscapeLink>
    );
};

export default Link;
