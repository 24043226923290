import { Box, Button, ColumnLayout, Header, Modal, SpaceBetween } from "@cloudscape-design/components";
import React, { FunctionComponent, useState } from "react";
import { FeatureFlagState } from "../../../types/FeatureFlagState";
import AllocationsBar from "../../common/AllocationsBar";
import ValueWithLabel from "../../common/ValueWithLabel";

export type DeleteFutureStateConfirmationModalProps = {
    stateToDelete: FeatureFlagState;
    onClose: () => void;
    onDelete: () => Promise<void>;
};

const DeleteFutureStateConfirmationModal: FunctionComponent<DeleteFutureStateConfirmationModalProps> = props => {
    const { stateToDelete, onClose, onDelete } = props;

    const [isDeleting, setIsDeleting] = useState(false);

    const handleSave = async () => {
        setIsDeleting(true);
        await onDelete();
        setIsDeleting(false);
    };

    return (
        <Modal
            visible={true}
            header={<Header variant="h2">Delete Future State</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button onClick={() => handleSave()} loading={isDeleting} variant="primary">
                            Delete
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => onClose()}
        >
            <SpaceBetween size="s">
                <div>Are you sure you want to delete this future state?</div>
                <ColumnLayout columns={2}>
                    <ValueWithLabel label="Activation Time">
                        {new Date(stateToDelete.activationTime).toLocaleString()}
                    </ValueWithLabel>
                    <ValueWithLabel label="Dialup Percentage">
                        <AllocationsBar allocations={stateToDelete.allocations} />{" "}
                    </ValueWithLabel>
                </ColumnLayout>
            </SpaceBetween>
        </Modal>
    );
};

export default DeleteFutureStateConfirmationModal;
