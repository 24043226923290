export const SERVICEAUTH_AUTHORITY = "https://weblogin.asu.edu/serviceauth";
export const SERVICEAUTH_CLIENT_ID = requireEnvVar("REACT_APP_SERVICEAUTH_CLIENT_ID");
export const SERVICEAUTH_REDIRECT_URI = requireEnvVar("REACT_APP_SERVICEAUTH_REDIRECT_URI");
export const SERVICEAUTH_SCOPE = requireEnvVar("REACT_APP_SERVICEAUTH_SCOPE");

export const API_BASE_URL = requireEnvVar("REACT_APP_API_BASE_URL");

function requireEnvVar(name: string): string {
    const value = process.env[name];
    if (!value) {
        throw new Error(`Missing required environment variable: ${name}`);
    }
    return value;
}
