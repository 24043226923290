import { ExpandableSection } from "@cloudscape-design/components";
import { FunctionComponent } from "react";

export type DebugProps = {
    value: object;
};

const Debug: FunctionComponent<DebugProps> = props => {
    return (
        <ExpandableSection headerText="Debug" variant="footer">
            <pre>{JSON.stringify(props.value, null, 2)}</pre>
        </ExpandableSection>
    );
};

export default Debug;
