import { ContentLayout, Header, SpaceBetween } from "@cloudscape-design/components";
import { FunctionComponent } from "react";
import { useAuth } from "react-oidc-context";
import FeatureFlagsTable from "../common/FeatureFlagsTable";

const HomePage: FunctionComponent = () => {
    const user = useAuth().user!.profile.sub;

    return (
        <ContentLayout header={<Header variant="h2">Home</Header>}>
            <SpaceBetween size="l">
                <FeatureFlagsTable headerText="Your Feature Flags" filters={{ user }} pageSize={10} />
                <FeatureFlagsTable headerText="All Feature Flags" filters={{}} pageSize={10} />
            </SpaceBetween>
        </ContentLayout>
    );
};

export default HomePage;
