import { FeatureFlagState } from "../../../types/FeatureFlagState";

export type OrderedStates = {
    currentState?: FeatureFlagState;
    pastStates: FeatureFlagState[];
    futureStates: FeatureFlagState[];
};

export default function orderStates(states: FeatureFlagState[]): OrderedStates {
    const now = new Date();
    const sortedStates = states.toSorted((a, b) => {
        return a.activationTime === b.activationTime ? 0 : a.activationTime < b.activationTime ? -1 : 1;
    });

    const currentStateIndex = sortedStates.findLastIndex(state => new Date(state.activationTime) <= now);
    if (currentStateIndex === -1) {
        return { pastStates: [], futureStates: sortedStates };
    } else {
        return {
            pastStates: sortedStates.slice(0, currentStateIndex),
            currentState: sortedStates[currentStateIndex],
            futureStates: sortedStates.slice(currentStateIndex + 1),
        };
    }
}
