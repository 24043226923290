import { BreadcrumbGroup, BreadcrumbGroupProps } from "@cloudscape-design/components";
import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumbs: FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();

    if (location.pathname === "/") {
        return null;
    }

    return (
        <BreadcrumbGroup
            items={parsePath(location.pathname)}
            onFollow={e => {
                e.preventDefault();
                navigate(e.detail.href);
            }}
        />
    );
};

const ROUTE_PATH_TO_BREADCRUMB_LABEL: Record<string, string> = {
    "feature-flags": "Feature Flags",
    create: "(Create New)",
    env: "Environments",
};

const NONROUTABLE_PATH_PARTS = new Set(["feature-flags", "env"]);

function parsePath(path: string): BreadcrumbGroupProps.Item[] {
    return path
        .split("/")
        .filter(part => part)
        .map((part, index, parts) => {
            const routeableIndex = NONROUTABLE_PATH_PARTS.has(part) ? index : index + 1;
            const href = "/" + parts.slice(0, routeableIndex).join("/");
            return {
                text: ROUTE_PATH_TO_BREADCRUMB_LABEL[part] ?? part,
                href,
            };
        });
}

export default Breadcrumbs;
