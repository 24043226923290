import { Box } from "@cloudscape-design/components";
import { FunctionComponent, PropsWithChildren } from "react";

export type ValueWithLabelProps = PropsWithChildren<{
    label: string;
}>;

const ValueWithLabel: FunctionComponent<ValueWithLabelProps> = props => {
    const { label, children } = props;
    return (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );
};

export default ValueWithLabel;
