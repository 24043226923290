import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../constants";
import { AddOverrideRequest } from "../types/api/AddOverrideRequest";
import { CreateFeatureFlagEnvStateRequest } from "../types/api/CreateFeatureFlagEnvStateRequest";
import { CreateFeatureFlagRequest } from "../types/api/CreateFeatureFlagRequest";
import { CreateFeatureFlagResponse } from "../types/api/CreateFeatureFlagResponse";
import { DeleteFutureStateRequest } from "../types/api/DeleteFutureStateRequest";
import { DeleteOverrideRequest } from "../types/api/DeleteOverrideRequest";
import { GetFeatureFlagResponse } from "../types/api/GetFeatureFlagResponse";
import { ListFeatureFlagsRequest } from "../types/api/ListFeatureFlagsRequest";
import { ListFeatureFlagsResponse } from "../types/api/ListFeatureFlagsResponse";
import { UpdateFeatureFlagRequest } from "../types/api/UpdateFeatureFlagRequest";
import { getAuthUser } from "../util/authUtil";

const effApi = createApi({
    reducerPath: "effApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: headers => {
            headers.set("Authorization", `Bearer ${getAuthUser()?.access_token}`);
            return headers;
        },
    }),
    tagTypes: ["FeatureFlag"],
    endpoints: builder => ({
        listFeatureFlags: builder.query<ListFeatureFlagsResponse, ListFeatureFlagsRequest>({
            query: (request: ListFeatureFlagsRequest) => {
                return {
                    url: "feature-flags",
                    params: {
                        ...request,
                    },
                };
            },
            providesTags: ["FeatureFlag"],
        }),

        getFeatureFlag: builder.query<GetFeatureFlagResponse, string>({
            query: (id: string) => {
                return {
                    url: `feature-flags/${id}`,
                };
            },
            providesTags: ["FeatureFlag"],
        }),

        createFeatureFlag: builder.mutation<CreateFeatureFlagResponse, CreateFeatureFlagRequest>({
            query: (request: CreateFeatureFlagRequest) => {
                return {
                    url: "feature-flags",
                    method: "POST",
                    body: request,
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        createFeatureFlagEnvState: builder.mutation<null, CreateFeatureFlagEnvStateRequest>({
            query: (request: CreateFeatureFlagEnvStateRequest) => {
                return {
                    url: `feature-flags/${request.ffId}/environments/${request.envId}/states`,
                    method: "POST",
                    body: {
                        allocations: request.allocations,
                        unsynchronized: request.unsynchronized,
                    },
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        deleteFutureState: builder.mutation<null, DeleteFutureStateRequest>({
            query: (request: DeleteFutureStateRequest) => {
                throw new Error("Function not implemented.");
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        addOverride: builder.mutation<null, AddOverrideRequest>({
            query: (request: AddOverrideRequest) => {
                return {
                    url: `feature-flags/${request.ffId}/environments/${request.envId}/overrides`,
                    method: "POST",
                    body: {
                        discriminator: request.discriminator,
                        treatment: request.treatment,
                    },
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        deleteOverride: builder.mutation<null, DeleteOverrideRequest>({
            query: (request: DeleteOverrideRequest) => {
                return {
                    url: `feature-flags/${request.ffId}/environments/${request.envId}/overrides/${encodeURIComponent(request.discriminator)}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        updateFeatureFlag: builder.mutation<null, UpdateFeatureFlagRequest>({
            query: (request: UpdateFeatureFlagRequest) => {
                return {
                    url: `feature-flags/${request.ffId}`,
                    method: "PUT",
                    body: {
                        displayName: request.displayName,
                        description: request.description,
                        owners: request.owners,
                        operators: request.operators,
                    },
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        archiveFeatureFlag: builder.mutation<null, string>({
            query: (id: string) => {
                return {
                    url: `feature-flags/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),

        restoreFeatureFlag: builder.mutation<null, string>({
            query: (id: string) => {
                return {
                    url: `feature-flags/${id}`,
                    method: "POST",
                };
            },
            invalidatesTags: ["FeatureFlag"],
        }),
    }),
});

export { effApi };

export const {
    useListFeatureFlagsQuery,
    useGetFeatureFlagQuery,
    useCreateFeatureFlagMutation,
    useCreateFeatureFlagEnvStateMutation,
    useDeleteFutureStateMutation,
    useAddOverrideMutation,
    useDeleteOverrideMutation,
    useUpdateFeatureFlagMutation,
    useArchiveFeatureFlagMutation,
    useRestoreFeatureFlagMutation,
} = effApi;
