import { Header, Pagination, Table } from "@cloudscape-design/components";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useListFeatureFlagsQuery } from "../../api/effApi";
import { ListFeatureFlagsRequest } from "../../types/api/ListFeatureFlagsRequest";
import { FeatureFlagSummary } from "../../types/FeatureFlagSummary";
import ApiErrorAlert from "./ApiErrorAlert";
import Link from "./Link";

export type FeatureFlagsTableProps = {
    headerText: string;
    pageSize?: number;
    filters: Partial<Omit<ListFeatureFlagsRequest, "pageToken" | "pageSize">>;
};

const FeatureFlagsTable: FunctionComponent<FeatureFlagsTableProps> = props => {
    const pageSize = props.pageSize || 10;
    const [pageNumber, setPageNumber] = useState(1);
    const [featureFlags, setFeatureFlags] = useState<Record<string, FeatureFlagSummary> | null>(null);
    const [nextPageToken, setNextPageToken] = useState<string | undefined>();
    const [currentPageToken, setCurrentPageToken] = useState<string | undefined>();
    const { data, error } = useListFeatureFlagsQuery({
        ...props.filters,
        pageToken: currentPageToken,
    });

    useEffect(() => {
        if (data) {
            setFeatureFlags(prev => {
                const newFeatureFlags = prev ?? {};
                data.featureFlags.forEach(flag => {
                    newFeatureFlags[flag.id] = flag;
                });
                return newFeatureFlags;
            });
            setNextPageToken(data.nextPageToken);
        }
    }, [data]);

    useEffect(() => {
        if (!!nextPageToken && Object.keys(featureFlags ?? {}).length < (pageNumber + 5) * pageSize) {
            setCurrentPageToken(nextPageToken);
        }
    }, [featureFlags, pageNumber, pageSize, nextPageToken]);

    return (
        <>
            <ApiErrorAlert error={error} />
            <Table
                header={<Header variant="h3">{props.headerText}</Header>}
                columnDefinitions={[
                    {
                        id: "id",
                        header: "ID",
                        cell: item => <Link to={`/feature-flags/${item.id}`}>{item.id}</Link>,
                        isRowHeader: true,
                    },
                    {
                        id: "name",
                        header: "Name",
                        cell: item => item.displayName,
                    },
                    {
                        id: "createdTime",
                        header: "Created",
                        cell: item => new Date(item.createdTime).toLocaleString(),
                    },
                ]}
                items={Object.values(featureFlags ?? {}).slice(pageSize * (pageNumber - 1), pageSize * pageNumber)}
                pagination={
                    <Pagination
                        currentPageIndex={pageNumber}
                        pagesCount={Math.ceil(Object.keys(featureFlags ?? {}).length / pageSize)}
                        openEnd={featureFlags === null || !!nextPageToken}
                        onChange={e => setPageNumber(e.detail.currentPageIndex)}
                        onNextPageClick={() => setPageNumber(pageNumber + 1)}
                        onPreviousPageClick={() => setPageNumber(pageNumber - 1)}
                    />
                }
                loading={featureFlags === null && !error}
                empty={<div>No feature flags found.</div>}
            />
        </>
    );
};

export default FeatureFlagsTable;
