import { ProgressBar } from "@cloudscape-design/components";
import { FunctionComponent } from "react";

export type AllocationsBarProps = {
    allocations?: Partial<Record<string, number>>;
};

const AllocationsBar: FunctionComponent<AllocationsBarProps> = props => {
    const { allocations } = props;

    return <ProgressBar value={allocations?.T1 ?? 0} />;
};

export default AllocationsBar;
