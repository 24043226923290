import { User } from "oidc-client-ts";
import { SERVICEAUTH_AUTHORITY, SERVICEAUTH_CLIENT_ID } from "../constants";

export function getAuthUser(): User | null {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${SERVICEAUTH_AUTHORITY}:${SERVICEAUTH_CLIENT_ID}`);
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}
