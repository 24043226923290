import { Box, Button, Header, Modal, SpaceBetween } from "@cloudscape-design/components";
import { FunctionComponent, PropsWithChildren, useState } from "react";

export type ConfirmationModalProps = PropsWithChildren & {
    headerText: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
};

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = props => {
    const { headerText, onClose, onConfirm, children } = props;
    const [isConfirming, setIsConfirming] = useState(false);

    const handleConfirm = async () => {
        setIsConfirming(true);
        await onConfirm();
        setIsConfirming(false);
    };

    return (
        <Modal
            visible={true}
            header={<Header>{headerText}</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button onClick={() => handleConfirm()} loading={isConfirming} variant="primary">
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => onClose()}
            size="medium"
        >
            {children}
        </Modal>
    );
};

export default ConfirmationModal;
