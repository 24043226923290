import {
    Button,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Textarea,
} from "@cloudscape-design/components";
import { Formik } from "formik";
import { FunctionComponent } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ObjectSchema } from "yup";
import { useCreateFeatureFlagMutation } from "../../api/effApi";
import { validatePermissions } from "../../util/permissionsUtil";
import ApiErrorAlert from "../common/ApiErrorAlert";
import Debug from "../common/Debug";
import WordListInput from "../common/WordListInput";

type FormState = {
    idPrefix: string;
    displayName: string;
    description: string;
    owners: string[];
    operators: string[];
};

const initialValues: FormState = {
    idPrefix: "",
    displayName: "",
    description: "",
    owners: [],
    operators: [],
};

const schema: ObjectSchema<Omit<FormState, "owners" | "operators">> = Yup.object().shape({
    idPrefix: Yup.string()
        .required("Required")
        .matches(/^[A-Z0-9][A-Z0-9_]*$/, "Must be uppercase alphanumeric characters with underscores"),
    displayName: Yup.string().required("Required").max(80, "Must be 80 characters or less"),
    description: Yup.string().ensure().max(1000, "Must be 1000 characters or less"),
});

const CreateFeatureFlagPage: FunctionComponent = () => {
    const [createFeatureFlag, { error }] = useCreateFeatureFlagMutation();
    const user = useAuth().user!.profile.sub;
    const navigate = useNavigate();

    return (
        <ContentLayout header={<Header variant="h2">Create Feature Flag</Header>}>
            <Container>
                <Formik
                    initialValues={{
                        ...initialValues,
                        owners: [user],
                    }}
                    onSubmit={async values => {
                        const createResponse = await createFeatureFlag({
                            idPrefix: values.idPrefix.replace(/_$/, ""),
                            displayName: values.displayName,
                            description: values.description,
                            owners: values.owners,
                            operators: values.operators,
                        });
                        if ("data" in createResponse) {
                            navigate(`/feature-flags/${createResponse.data.id}`);
                        }
                    }}
                    validate={values => {
                        const permissionsErrors = validatePermissions({
                            owners: values.owners,
                            operators: values.operators,
                        });

                        return {
                            ...permissionsErrors,
                        };
                    }}
                    validationSchema={schema}
                >
                    {({ values, errors, touched, setFieldValue, setFieldTouched, submitForm, isSubmitting }) => {
                        const handleChange = (field: keyof FormState, value: FormState[keyof FormState]) => {
                            setFieldTouched(field, true, false);
                            setFieldValue(field, value, true);
                        };

                        return (
                            <Form
                                actions={
                                    <Button variant="primary" onClick={() => submitForm()} loading={isSubmitting}>
                                        Create
                                    </Button>
                                }
                            >
                                <SpaceBetween size="l">
                                    <FormField
                                        label="ID Prefix"
                                        constraintText="Required"
                                        description="The identifier for the feature flag. A random numeric suffix will be appended to this value for the final ID."
                                        errorText={touched.idPrefix && errors.idPrefix}
                                    >
                                        <Input
                                            name="idPrefix"
                                            value={values.idPrefix}
                                            onChange={e =>
                                                handleChange(
                                                    "idPrefix",
                                                    e.detail.value
                                                        ?.toUpperCase()
                                                        ?.replace(/[ -]/g, "_")
                                                        ?.replace(/[^A-Z0-9_]/g, "") ?? "",
                                                )
                                            }
                                        />
                                    </FormField>

                                    <FormField
                                        label="Display Name"
                                        constraintText="Required"
                                        errorText={touched.displayName && errors.displayName}
                                        description="The display friendly name for the feature flag."
                                    >
                                        <Input
                                            name="displayName"
                                            value={values.displayName}
                                            onChange={e => handleChange("displayName", e.detail.value)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Description"
                                        errorText={touched.description && errors.description}
                                        constraintText="Optional"
                                    >
                                        <Textarea
                                            name="description"
                                            value={values.description}
                                            onChange={e => handleChange("description", e.detail.value)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Owners"
                                        description="Users that should have full control over the feature flag."
                                        errorText={touched.owners && errors.owners}
                                        constraintText="At least 1 required"
                                    >
                                        <WordListInput
                                            values={values.owners}
                                            onChange={owners => handleChange("owners", owners)}
                                        />
                                    </FormField>

                                    <FormField
                                        label="Operators"
                                        description="Users that should be able to dialup, dialdown, and modify overrides for the feature flag."
                                        errorText={touched.operators && errors.operators}
                                        constraintText="Optional"
                                    >
                                        <WordListInput
                                            values={values.operators}
                                            onChange={operators => handleChange("operators", operators)}
                                        />
                                    </FormField>

                                    <ApiErrorAlert error={error} />

                                    <Debug value={{ values, errors, touched }} />
                                </SpaceBetween>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </ContentLayout>
    );
};

export default CreateFeatureFlagPage;
