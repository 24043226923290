import { Input, TokenGroup } from "@cloudscape-design/components";
import { FunctionComponent, useState } from "react";

export type WordListInputProps = {
    values: string[];
    onChange: (values: string[]) => void;
};

const WordListInput: FunctionComponent<WordListInputProps> = props => {
    const { values, onChange } = props;

    const [currentText, setCurrentText] = useState("");

    const handleCapture = () => {
        if (!currentText) {
            return;
        }

        onChange([...values, ...currentText.split(/,?\s+/).filter(s => !!s)]);
        setCurrentText("");
    };

    return (
        <>
            <Input
                name="newOwner"
                value={currentText}
                onChange={e => setCurrentText(e.detail.value)}
                onKeyDown={e => {
                    if (e.detail.key === "Enter") {
                        e.preventDefault();
                        handleCapture();
                    }
                }}
                onBlur={() => {
                    handleCapture();
                }}
            />
            <TokenGroup
                items={values.map(value => ({ id: value, label: value }))}
                onDismiss={e => onChange(values.filter((_, i) => i !== e.detail.itemIndex))}
            />
        </>
    );
};

export default WordListInput;
