import { Alert, Box, Button, FormField, Header, Modal, SpaceBetween } from "@cloudscape-design/components";
import { FunctionComponent, useState } from "react";
import { useAuth } from "react-oidc-context";

import { UpdateFeatureFlagRequest } from "../../../types/api/UpdateFeatureFlagRequest";
import { FeatureFlagPermissions, validatePermissions } from "../../../util/permissionsUtil";
import WordListInput from "../../common/WordListInput";

export type ModifyPermissionsModalProps = {
    currentPermissions: FeatureFlagPermissions;
    onClose: () => void;
    onSave: (permissions: Pick<UpdateFeatureFlagRequest, "owners" | "operators">) => Promise<void>;
};

const ModifyPermissionsModal: FunctionComponent<ModifyPermissionsModalProps> = props => {
    const { currentPermissions, onClose, onSave } = props;

    const user = useAuth().user!.profile.sub;
    const [isSaving, setIsSaving] = useState(false);
    const [owners, setOwners] = useState(currentPermissions.owners);
    const [operators, setOperators] = useState(currentPermissions.operators);
    const [ownersError, setOwnersError] = useState("");
    const [operatorsError, setOperatorsError] = useState("");

    const validate = (permissions: FeatureFlagPermissions) => {
        const errors = validatePermissions(permissions);
        setOwnersError(errors.owners ?? "");
        setOperatorsError(errors.operators ?? "");
    };

    const handleOwnersChange = (newOwners: string[]) => {
        setOwners(newOwners);
        validate({ owners: newOwners, operators });
    };

    const handleOperatorsChange = (newOperators: string[]) => {
        setOperators(newOperators);
        validate({ owners, operators: newOperators });
    };

    const handleSave = async () => {
        setIsSaving(true);
        await onSave({ owners, operators });
        setIsSaving(false);
    };

    return (
        <Modal
            visible={true}
            header={<Header>Modify Permissions</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            loading={isSaving}
                            disabled={!!ownersError || !!operatorsError}
                            variant="primary"
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => onClose()}
            size="medium"
        >
            <SpaceBetween size="m">
                {!owners.includes(user) && (
                    <Alert type="warning">
                        You are removing yourself as an owner. You will lose the ability to modify permissions after you
                        save.
                    </Alert>
                )}

                <FormField label="Owners" errorText={ownersError}>
                    <WordListInput values={owners} onChange={handleOwnersChange} />
                </FormField>

                <FormField label="Operators" errorText={operatorsError}>
                    <WordListInput values={operators} onChange={handleOperatorsChange} />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

export default ModifyPermissionsModal;
