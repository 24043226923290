import { Alert, ExpandableSection } from "@cloudscape-design/components";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FunctionComponent, ReactNode } from "react";

type ApiError = FetchBaseQueryError | SerializedError;

export type ApiErrorAlertProps =
    | {
          error: ApiError | undefined;
      }
    | {
          errors: Array<ApiError | undefined>;
      };

const ApiErrorAlert: FunctionComponent<ApiErrorAlertProps> = props => {
    const errorInput: Array<ApiError | undefined> = [];
    if ("error" in props) {
        errorInput.push(props.error);
    } else {
        errorInput.push(...props.errors);
    }

    const renderError = (error: ApiError): ReactNode => {
        if ("status" in error) {
            if (typeof error.status === "number") {
                const errorData = error.data as Record<string, unknown>;
                return `API returned an error (${error.status}). Message: ${errorData?.message}. ${errorData?.errors ? `Error: ${errorData.errors}` : ""}`;
            } else {
                return `An error occurred: ${error.status}`;
            }
        } else {
            return `An error occured: ${error.name} - ${error.message}`;
        }
    };

    return (
        <>
            {errorInput &&
                errorInput
                    .filter(error => error !== undefined)
                    .map((error, i) => (
                        <Alert key={i} type="error">
                            {renderError(error!)}
                            <ExpandableSection headerText="Details">
                                <pre>{JSON.stringify(error, null, 2)}</pre>
                            </ExpandableSection>
                        </Alert>
                    ))}
        </>
    );
};

export default ApiErrorAlert;
